
import { defineComponent } from 'vue'
import NavBar from "@/components/NavBar.vue"
import Button from "@/components/Button.vue"
import SideBar from "@/components/SideBar.vue"

// Utils
import utilities from './utilities'

export default defineComponent({
  name: "App",
  components: {
    NavBar,
    Button,
    SideBar,
  },
  data() {
    return {
      token: '',
      devMode: false,
      openMenu: false,
      dropdownOpen: false,
      config: [] as any[any],
      menuOptions: [
        {
          icon: '/img/user-solid.svg',
          label: 'Mi Cuenta',
          url: '/mi-cuenta',
          class: 'lg:hidden',
        },
        {
          icon: '/img/house-solid.svg',
          label: 'Tienda',
          url: '/tienda',
        },
        {
          icon: '/img/circle-info-solid.svg',
          label: 'Sobre Nosotros',
          url: '/sobre-nosotros',
        },
        {
          icon: '/img/circle-question-solid.svg',
          label: 'Preguntas Frecuentes',
          url: '/preguntas-frecuentes',
        }
      ],
      dropdownOptions: [
        {
          icon: '/img/user-solid.svg',
          label: 'Consulta Médica',
          url: '/turnos',
        },
        {
          icon: '/img/credit-card-solid.svg',
          label: 'Pedí tu credencial',
          url: '/pedir-credencial',
        },
        {
          icon: '/img/club-icon.svg',
          label: 'Asesoramiento Clubes',
          url: '/asesoramiento-clubes',
        },
      ]
    }
  },
  mounted() {
    this.devMode = localStorage.getItem('devMode') as any

    //get config data
    let configData = utilities.getItems('config/1?fields=*') as any
    Promise.resolve(configData).then((data) => {
      this.config = data
    })

    //check auth
    const token = utilities.checkAuth() as any
    Promise.resolve(token).then((data: string) => {
      if (typeof data != 'undefined') {
        this.token = data
      }
    })
  },
  methods: {
    toggleMenu: function () {
      this.openMenu = !this.openMenu
    },
    toggleDropdown: function () {
      this.dropdownOpen = !this.dropdownOpen
    }
  },
  watch: {
    $route(to, from) {
      // scrollto top
      window.scrollTo(0, 0)

      // close sidebar
      this.openMenu = false
      this.dropdownOpen = false

      // token
      const token = utilities.checkAuth() as any
      Promise.resolve(token).then((data: string) => {
        if (typeof data != 'undefined') {
          this.token = data
        } else {
          this.token = ''
        }
      })
    }
  }
})
